exports.onClientEntry = () => {
    // const { search } = window.location
    // const query = search.replace('?', '').trim()
   
    // const paramsSplit = query.split('&')
    // const params = paramsSplit[0] === '' ? [] : paramsSplit

    // console.log(params)


    // params.map((param) => {
    //   const p = param.split('=')
    //   const key = p[0]
    //   const value = p.length > 0 ? p[1] : ''
    
    //   return value
    // })

    
    // if (window.sessionStorage && !!params.length) {
    //   const currentValue = window.sessionStorage.getItem('storeSelection')
    //   window.sessionStorage.setItem(
    //     'storeSelection',
    //     JSON.stringify({ ...JSON.parse(currentValue) })
    //   )
    // }
  }